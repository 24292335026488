/* ⚠️⚠️⚠️
This is not a "general" entrypoint. DO NOT add code that you expect to run here.
This entrypoint's purpose is to hide some JS code from search crawlers.
The name is to obfuscate the meaning of this file.
⚠️⚠️⚠️
*/

import AtcController from "../seo_hidden_links/atc/atc_controller"
import AtcExperimentController from "../seo_hidden_links/atc_experiment/atc_experiment_controller"

window.Stimulus.register("atc", AtcController)
window.Stimulus.register("atc-experiment", AtcExperimentController)
