import { Controller } from "@hotwired/stimulus"
import { decryptLink } from "@utils/decryption/decryption.utils"
import { copyAttributesAndChildren } from "@utils/html/html.utils"

// Experiment explained in https://hellowork.atlassian.net/browse/SE-7981
export default class extends Controller<HTMLElement> {
  static values = {
    l: String,
    t: String,
  }
  readonly lValue: string
  readonly tValue: string

  connect(): void {
    const decryptedLink = document.createElement("a")
    copyAttributesAndChildren(this.element, { into: decryptedLink })
    decryptedLink.dataset.controller = decryptedLink.dataset.controller.replace(this.identifier, "")

    decryptedLink.href = decryptLink(this.lValue)
    if (this.tValue) {
      decryptedLink.target = decryptLink(this.tValue)
    }

    this.element.replaceWith(decryptedLink)
  }
}
