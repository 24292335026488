export function decryptLink(encrypted: string): string {
  return rot13(encryptedLinkToRot13(encrypted))
}

/** Transforms our proprietary encryption into a rot13 cypher */
function encryptedLinkToRot13(value: string): string {
  return value
    .slice(1, -1) // Remove the first and last characters
    .replace(/=pt=/g, ".") // Change "=pt=" into "."
}

const ASCII_LETTER_REGEX = /[a-z]/gi
function rot13(encrypted: string): string {
  return encrypted.replace(ASCII_LETTER_REGEX, rot13OneLetter)
}

function rot13OneLetter(letter: string): string {
  const charCode = letter.charCodeAt(0)
  const exceedsM = letter.toLowerCase() > "m"
  return String.fromCharCode(exceedsM ? charCode - 13 : charCode + 13)
}
